<script setup lang="ts"></script>

<template>
	<section class="relative z-10 py-12 xl:py-16 text-center text-white px-4">
		<!-- Titolo -->
		<h2 class="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6">
			{{ $t("Trusted by Developers & Businesses") }}
		</h2>
		<p
			class="text-gray-300 max-w-xl mx-auto mb-8 sm:mb-10 text-base sm:text-lg"
		>
			{{ $t("Discover my work and expertise across different platforms.") }}
		</p>

		<!-- Social Cards -->
		<div
			class="grid grid-cols-1 sm:grid-cols-2 md:flex justify-center gap-4 sm:gap-6"
		>
			<!-- Card -->
			<a
				target="_blank"
				href="https://github.com/fezz02"
				class="flex items-center gap-4 p-4 bg-gray-200/5 border border-white/20 rounded-md shadow-lg transition-all duration-300 ease-in-out hover:border-purple-500 hover:shadow-purple-500/20 hover:scale-[1.005]"
			>
				<svg
					viewBox="0 0 32 32"
					xmlns="http://www.w3.org/2000/svg"
					width="40"
					height="40"
					fill="currentColor"
					class="text-gray-300"
				>
					<path
						d="M16 .396c-8.836 0-16 7.164-16 16 0 7.073 4.583 13.066 10.944 15.183.8.147 1.091-.347 1.091-.771 0-.379-.014-1.387-.022-2.722-4.451.967-5.391-2.147-5.391-2.147-.728-1.847-1.777-2.339-1.777-2.339-1.453-.992.11-.971.11-.971 1.606.115 2.451 1.649 2.451 1.649 1.429 2.448 3.748 1.74 4.661 1.327.145-1.034.558-1.74 1.015-2.14-3.554-.406-7.288-1.777-7.288-7.906 0-1.747.623-3.174 1.646-4.29-.165-.404-.714-2.031.157-4.238 0 0 1.342-.43 4.396 1.64 1.275-.354 2.646-.53 4.005-.535 1.359.005 2.73.181 4.005.535 3.054-2.07 4.396-1.64 4.396-1.64.871 2.207.322 3.834.157 4.238 1.024 1.116 1.646 2.543 1.646 4.29 0 6.145-3.74 7.495-7.303 7.895.574.495 1.086 1.471 1.086 2.966 0 2.141-.02 3.864-.02 4.392 0 .428.287.922 1.098.766C27.42 29.457 32 23.469 32 16.396c0-8.836-7.164-16-16-16z"
					/>
				</svg>
				<div class="text-left">
					<h3 class="text-lg sm:text-xl font-semibold text-purple-400">
						GitHub
					</h3>
					<p class="text-gray-300 text-sm">🔥 +5k Contributions</p>
				</div>
			</a>

			<!-- Upwork -->
			<a
				target="_blank"
				href="https://www.upwork.com/freelancers/~018708cf17ec112e5f?mp_source=share"
				class="flex items-center gap-4 p-4 bg-gray-200/5 border border-white/20 rounded-md shadow-lg transition-all duration-300 ease-in-out hover:border-purple-500 hover:shadow-purple-500/20 hover:scale-[1.005]"
			>
				<svg
					fill="currentColor"
					viewBox="0 0 32 32"
					xmlns="http://www.w3.org/2000/svg"
					width="40"
					height="40"
					class="text-gray-300"
				>
					<path
						d="M24.75 17.542c-1.469 0-2.849-0.62-4.099-1.635l0.302-1.432 0.010-0.057c0.276-1.521 1.13-4.078 3.786-4.078 1.99 0 3.604 1.615 3.604 3.604 0 1.984-1.615 3.599-3.604 3.599zM24.75 6.693c-3.385 0-6.016 2.198-7.083 5.818-1.625-2.443-2.865-5.38-3.583-7.854h-3.646v9.484c-0.005 1.875-1.521 3.391-3.396 3.396-1.875-0.005-3.391-1.526-3.396-3.396v-9.484h-3.646v9.484c0 3.885 3.161 7.068 7.042 7.068 3.885 0 7.042-3.182 7.042-7.068v-1.589c0.708 1.474 1.578 2.974 2.635 4.297l-2.234 10.495h3.729l1.62-7.615c1.417 0.906 3.047 1.479 4.917 1.479 4 0 7.25-3.271 7.25-7.266 0-4-3.25-7.25-7.25-7.25z"
					></path>
				</svg>
				<div class="text-left">
					<h3 class="text-lg sm:text-xl font-semibold text-purple-400">
						Upwork
					</h3>
					<p class="text-gray-300 text-sm">🏆 100% Job Success</p>
				</div>
			</a>

			<!-- Fiverr -->
			<a
				target="_blank"
				href="https://www.fiverr.com/fedkerr6"
				class="flex items-center gap-4 p-4 bg-gray-200/5 border border-white/20 rounded-md shadow-lg transition-all duration-300 ease-in-out hover:border-purple-500 hover:shadow-purple-500/20 hover:scale-[1.005]"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="40"
					height="40"
					viewBox="-2.5 -2 24 24"
					class="text-gray-300"
				>
					<g fill="currentColor">
						<path
							d="M16.25 16.25v-10h-10v-.625c0-1.034.841-1.875 1.875-1.875H10V0H8.125A5.632 5.632 0 0 0 2.5 5.625v.625H0V10h2.5v6.25H0V20h8.75v-3.75h-2.5V10h6.285v6.25H10V20h8.75v-3.75z"
						/>
						<circle cx="14.375" cy="1.875" r="1.875" />
					</g>
				</svg>
				<div class="text-left">
					<h3 class="text-lg sm:text-xl font-semibold text-purple-400">
						Fiverr
					</h3>
					<p class="text-gray-300 text-sm">⭐ 5.0/5 - 10+ Reviews</p>
				</div>
			</a>
		</div>
	</section>
</template>
