<script setup lang="ts">
	import NavLink from "~/components/Navigation/NavLink.vue";
	import BackgroundEffects from "./BackgroundEffects.vue";

	const localePath = useLocalePath();
	const caseStudies = [
		{
			title: "Streamlining Fitness Studio Operations",
			link: "case-study/fitness",
			image: "assets/yoga.avif",
			sizes: "(max-width: 768px) 100vw, 33vw",
			catchy:
				"We managed to reduce the administrative workload by 80% in over 10 Yoga and Pilates studios across Italy, enabling a sharper focus on delivering exceptional wellness experiences.",
			name: "case-study-fitness",
		},
		{
			title: "Scaling Multi-Store Amazon Operations",
			link: "/case-study/amazon-saas",
			image: "assets/amazon.avif",
			sizes: "(max-width: 768px) 60vw, 33vw",
			catchy:
				"A well-known European company, Amazon partner, successfully managed 5+ stores with ease using their SaaS: centralized inventory management, automated ad campaigns, and optimized operations.",
			name: "case-study-amazon",
		},
		{
			title: "Expanding Rental Services with Zero Friction",
			link: "/case-study/vehicle-rental",
			image: "assets/rent.avif",
			sizes: "(max-width: 768px) 80vw, 25vw",
			catchy:
				"A motorcycle rental company scaled from a single location to 5 branches and expanded into car rentals — all while running other businesses effortlessly, saving time and resources.",
			name: "case-study-rental",
		},
	];
</script>

<template>
	<section
		class="rounded-t-[3rem] bg-gradient-to-b from-purple-500 via-gray-800 to-gray-900 relative py-10 sm:py-20 text-white text-center overflow-hidden shadow-[0px_-1px_20px_0px] shadow-purple-700 backdrop-blur-md px-4"
	>
		<BackgroundEffects />
		<div class="absolute inset-0 bg-dot-pattern opacity-20"></div>

		<div class="relative z-10">
			<h2 class="text-2xl lg:text-3xl font-bold mb-4 sm:mb-6">
				{{ $t("Case Studies") }}
			</h2>
			<p
				class="text-gray-300 max-w-2xl mx-auto mb-8 sm:mb-10 text-md lg:text-lg"
			>
				{{
					$t("Explore how our digital solutions are transforming industries.")
				}}
			</p>

			<div
				class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto"
			>
				<CardContainer v-for="study in caseStudies" :key="study.title">
					<NuxtImg
						:src="study.image"
						:alt="`Case study: ${study.title}`"
						class="w-full h-48 sm:h-52 object-cover rounded-t-md"
						format="webp"
						width="600"
						height="300"
						quality="30"
						:sizes="study.sizes"
						:placeholder="true"
						decoding="async"
						loading="lazy"
					/>

					<div
						class="p-6 sm:p-8 text-center flex flex-col justify-between h-full"
					>
						<h3 class="text-md sm:text-lg mb-2 font-semibold text-purple-400">
							{{ $t(study.title) }}
						</h3>
						<p class="text-gray-300 text-md flex-1">
							{{ $t(study.catchy) }}
						</p>
						<!-- <NuxtLink
							:to="localePath({ name: study.name })"
							class="text-purple-400 mt-4 inline-block text-sm font-semibold hover:underline transition-all"
						>
							Learn more →
						</NuxtLink> -->
					</div>
				</CardContainer>
			</div>
		</div>
		<p class="text-xs text-gray-400 mt-6 max-w-4xl mx-auto">
			Disclaimer:
			{{
				$t(
					"The companies mentioned in these case studies have not given formal consent to display their brand names or logos. The content is based on real implementations and results, anonymized for privacy and confidentiality."
				)
			}}
		</p>
	</section>
</template>
