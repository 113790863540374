<script setup lang="ts">
	import { onMounted, nextTick } from "vue";
	import anime from "animejs";

	onMounted(async () => {
		await nextTick();

		anime
			.timeline()
			.add({
				targets: ".intro-card",
				opacity: [0, 1],
				translateY: [0, 0],
				duration: 400,
				easing: "easeOutExpo",
			})
			.add({
				targets: ".intro-logo",
				scale: [0.8, 1],
				opacity: [0, 1],
				duration: 400,
				easing: "easeOutBack",
			}); // parte 600ms prima che la card finisca
	});
</script>

<template>
	<div
		class="intro-card opacity-0 relative z-10 flex items-center max-w-xl bg-gray-200/5 border border-white/20 rounded-sm shadow-lg p-4 sm:p-6 xl:p-8 transition-all duration-300 ease-in-out hover:border-purple-500 hover:shadow-purple-500/20 hover:scale-[1.005]"
	>
		<div class="mr-4">
			<h3 class="text-lg font-semibold text-purple-400 text-left">
				{{ $t("Nice to meet you!") }} 🙌
			</h3>
			<p class="text-gray-300 text-sm mt-1 text-justify">
				{{
					$t(
						"I’m Federico, a Web Developer & Laravel Specialist from Italy. I specialize in Laravel SaaS & Multi-Tenant applications, building high-performance platforms tailored to your business needs."
					)
				}}
			</p>
		</div>
		<!-- <div class="h-32 border-l border-purple-500"></div> -->
		<img
			src="assets/fezz_logo.png"
			alt="Fezz"
			class="intro-logo w-16 h-16 opacity-0"
		/>
	</div>
</template>
