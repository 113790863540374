<script setup lang="ts">
	const props = withDefaults(
		defineProps<{
			light?: boolean;
		}>(),
		{
			light: false,
		}
	);
</script>
<template>
	<div
		:class="{
			'border-white/20': !props.light,
			'border-black/20': props.light,
		}"
		class="bg-gray-200/5 border rounded-sm shadow-lg flex flex-col items-center transition-all duration-300 ease-in-out hover:border-purple-500 hover:shadow-purple-500/20 hover:scale-[1.02]"
	>
		<slot />
	</div>
</template>
