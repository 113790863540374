<script setup lang="ts">
	import { onMounted } from "vue";
	import anime from "animejs";

	onMounted(() => {
		anime({
			targets: ".fade-up",
			opacity: [0, 1],
			translateY: [20, 0],
			duration: 800,
			delay: anime.stagger(150, { start: 200 }),
			easing: "easeOutQuad",
		});
	});
</script>

<template>
	<section
		class="animated-section relative z-10 text-center lg:text-left text-white max-w-2xl"
	>
		<p
			class="fade-up text-sm uppercase tracking-wide text-purple-400 border border-purple-400 px-3 py-1 rounded-sm inline-block"
		>
			{{ $t("Web Application & SaaS Development") }}
		</p>

		<h1
			class="fade-up text-3xl lg:text-4xl xl:text-5xl font-bold tracking-wide mt-4 animate-textGlow"
		>
			{{ $t("Turning ideas into high-performance digital solutions.") }}
		</h1>

		<p class="fade-up text-gray-300 text-md xl:text-lg italic mt-2">
			{{
				$t(
					"I don’t just develop web applications – I build tailored solutions with a strong technical foundation, ensuring seamless scalability and maintainability."
				)
			}}
			🚀
		</p>

		<div class="fade-up">
			<button
				class="animated-button mt-6 px-6 py-4 text-lg font-medium border-2 border-purple-500 text-purple-400 bg-transparent rounded-sm transition-all duration-500 ease-in-out hover:bg-purple-500 hover:text-white hover:shadow-purple-500/30 hover:shadow-lg hover:scale-[1.02] active:scale-[0.98]"
			>
				{{ $t("Let’s build your next big project!") }}
			</button>
		</div>
	</section>
</template>

<style scoped>
	@keyframes textGlow {
		0% {
			text-shadow: 0 0 10px rgba(128, 0, 255, 0.2);
		}
		50% {
			text-shadow: 0 0 15px rgba(128, 0, 255, 0.3);
		}
		100% {
			text-shadow: 0 0 10px rgba(128, 0, 255, 0.2);
		}
	}
	.animate-textGlow {
		animation: textGlow 3s infinite ease-in-out;
	}
</style>
