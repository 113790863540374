<script setup lang="ts">
	const props = withDefaults(
		defineProps<{
			title: string;
			icon: string[];
			catch: string;
			description: string;
			light?: boolean;
		}>(),
		{
			light: false,
		}
	);
</script>
<template>
	<CardContainer :light="props.light">
		<font-awesome
			:icon="props.icon"
			class="text-4xl"
			:class="{
				'text-purple-500': !props.light,
				'text-purple-700': props.light,
			}"
		/>
		<slot />
		<h3
			class="text-xl font-semibold mt-3"
			:class="{
				'text-purple-500': !props.light,
				'text-purple-700': props.light,
			}"
		>
			{{ $t(props.title) }}
		</h3>
		<p
			class="text-sm mt-1"
			:class="{
				'text-gray-300': !props.light,
				'text-gray-700': props.light,
			}"
		>
			<strong>{{ $t(props.catch) }}</strong>
			{{ $t(props.description) }}
		</p>
	</CardContainer>
</template>
