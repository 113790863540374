<script setup lang="ts"></script>

<template>
	<div>
		<!-- Background Effects -->
		<div
			class="absolute inset-0 bg-gradient-to-br from-purple-700/40 to-transparent blur-[120px] opacity-50 animate-pulse pointer-events-none"
		></div>
		<div
			class="absolute inset-0 bg-dot-pattern opacity-20 pointer-events-none"
		></div>
	</div>
</template>

<style scoped>
	/* Subtle Dot Pattern */
	.bg-dot-pattern {
		background-image: radial-gradient(
			circle,
			rgba(255, 255, 255, 0.05) 2px,
			transparent 2.5px
		);
		background-size: 8px 8px;
	}
</style>
