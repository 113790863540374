<script setup lang="ts">
	import { ref } from "vue";
	import BackgroundEffects from "./BackgroundEffects.vue";

	const cards = ref([
		{
			title: "Scalable SaaS Solutions",
			icon: ["fas", "server"],
			catch: "Built for growth, security, and seamless expansion.",
			description:
				"Custom cloud-based solutions that scale with your business, ensuring smooth operations and automated efficiency.",
		},
		{
			title: "Intuitive Admin Interfaces",
			icon: ["fas", "desktop"],
			catch: "Designed for simplicity and efficiency.",
			description:
				"User-friendly dashboards that simplify management and enhance productivity.",
		},
		{
			title: "Effortless Deployments",
			icon: ["fas", "cloud-upload-alt"],
			catch: "Zero-downtime updates, fully automated.",
			description:
				"Reliable and fully automated updates with zero downtime, ensuring seamless user experiences.",
		},
		{
			title: "Enterprise-Grade Security",
			icon: ["fas", "shield-alt"],
			catch: "Maximum protection for your data and users.",
			description:
				"Military-grade encryption, secure authentication, and Cloudflare protection for unmatched security.",
		},
		{
			title: "Seamless Integrations",
			icon: ["fas", "plug"],
			catch: "Everything connected, effortlessly.",
			description:
				"Seamlessly integrate third-party services, payment gateways, and automation tools into your workflow.",
		},
		{
			title: "Optimized Performance",
			icon: ["fas", "tachometer-alt"],
			catch: "Lightning-fast load times & smooth operations.",
			description:
				"Blazing-fast performance powered by caching, query optimizations, and smart load balancing.",
		},
		{
			title: "Real-Time Capabilities",
			icon: ["fas", "bolt"],
			catch: "Instant interactions, live data updates.",
			description:
				"Instant updates and real-time interactions for dynamic, engaging user experiences.",
		},
		{
			title: "AI-Powered Automation",
			icon: ["fas", "robot"],
			catch: "Smart assistants handling tasks for you.",
			description:
				"AI-driven automation that reduces manual work, optimizes workflows, and enhances decision-making.",
		},
	]);
</script>

<template>
	<section
		class="rounded-t-[3rem] bg-gradient-to-b from-white via-gray-100 to-gray-300 relative py-10 sm:py-20 text-gray-900 text-center shadow-[0px_-1px_20px_0px] shadow-gray-400 backdrop-blur-sm px-4"
	>
		<h2 class="text-2xl lg:text-3xl font-bold mb-4 sm:mb-6">
			{{ $t("Unlocking the Full Potential of Modern Web Applications") }}
		</h2>
		<p class="text-gray-800 max-w-2xl mx-auto mb-8 sm:mb-10 text-md lg:text-lg">
			<i18n-t keypath="leverage">
				<strong>{{ $t("scalable, secure, and high-performance") }}</strong>
			</i18n-t>
		</p>
		<div
			class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 max-w-6xl mx-auto"
		>
			<CardGlassCard
				class="p-4 sm:p-6"
				v-for="(card, index) in cards"
				:key="index"
				v-bind="card"
				light
			/>
		</div>
	</section>
</template>

<style scoped>
	.shadow-transition {
		box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.5);
	}

	.bg-dotted {
		background-image: radial-gradient(
			circle,
			rgba(0, 0, 0, 0.2) 1px,
			transparent 1px
		);
		background-size: 20px 20px;
	}
</style>
